/* root index.css */

html {
  --c-bg: #f9f9f9;
  --c-focus: #fff;
  --c-light-text: rgb(0, 0, 0, 0.2);
  --c-fg: #000;
  background: var(--c-bg);
}

[hidden] {
  display: none !important;
}

* {
  box-sizing: border-box;
}

a {
  color: rgb(0, 0, 215);
}

.error {
  border: 4px solid rgb(255, 112, 112);
  border-radius: 8px;
  padding: 20px;
}

.error p:first-child {
  margin-top: 0;
}

code {
  background: #eee;
  border: 2px solid #eee;
  border-radius: 2px;
}

pre {
  background: #eee;
  border: 2px solid #eee;
  border-radius: 2px;
  padding: 16px;
}

pre code {
  border: 0;
}

label {
  cursor: pointer;
}

button,
.button {
  cursor: pointer;
  padding: 4px 16px;
  margin: 4px 0;
  background: #222;
  color: white;
  /* font-family: 'source code pro'; */
  font-family: 'source code pro', 'ubuntu Mono', monospace;
  border: 0;
  font-size: 16px;
  line-height: 1.5;
}

button.selected,
.button.selected {
  outline: 2px dashed white !important;
}

button:hover,
.button:hover {
  background: #000;
  outline: 2px solid #000;
}

button img {
  vertical-align: middle;
}

body,
html {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, */
  /* Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif; */
  min-height: 100%;
  line-height: 1.6;
}

* {
  font-family: 'source code pro', 'ubuntu Mono', monospace;
}

body {
  margin: 20px;
  min-height: calc(100vh - 40px);
}

nav ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  background: linear-gradient(#111, black);
  color: white;
  margin-bottom: 20px;
  padding: 8px;
  border-radius: 2px;
}

nav li {
  padding: 8px 16px;
}

nav li.selected:before {
  content: '❯ ';
}

body > nav li:last-child {
  flex-grow: 1;
  text-align: right;
}

nav a {
  color: white;
  text-decoration: none;
}

nav a:hover {
  text-decoration: underline;
}

details {
  display: block;
}

details summary {
  cursor: pointer;
}

@media screen and (max-width: 480px) {
  nav ul {
    justify-content: space-between;
  }
}

button svg {
  vertical-align: middle;
}

select {
  vertical-align: middle;
  color: black;
}

label > span + select {
  margin-left: 10px;
}

p,
hr {
  max-width: 720px;
}

hr {
  border: 1px solid #666;
  border-radius: 4px;
  margin-left: 0;
}

details footer {
  font-size: 90%;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.align-center {
  align-items: center;
}

.toast {
  position: fixed;
  bottom: 16px;
  right: 16px;
  width: 300px;
  border-radius: 4px;
  transition: all 200ms ease-out;
  transform: translateY(400px);
  overflow: hidden;
  font-size: 0.75rem;
  background-color: var(--c-bg);
  background-color: var(--c-focus);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
}

.show-toast .toast {
  transform: translateY(0);
  z-index: 100;
}

.toast .close {
  border: 0;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  width: initial;
}

.toast-cloud {
  fill: var(--c-fg);
}

.toast-cloud-tick {
  fill: var(--c-bg);
}

.toast .close:hover {
  border: 0;
  outline: 0;
  opacity: 1;
}

.toast-header {
  padding: 8px 12px;

  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}

.toast svg .filler {
  fill: var(--c-fg);
}

.toast-header button {
  background: none;
  padding: 0;
  color: black;
}

.toast-title {
  margin-left: 8px;
  flex: 1;
}

.toast-body {
  padding: 12px;
}

.keys {
  list-style: none;
  padding-left: 0;
}

.keys dd {
  margin-bottom: 16px;
  line-height: 1.2;
}

.keys dt {
  font-weight: bold;
  line-height: 1.2;
}

small {
  display: inline-block;
  line-height: 1.4;
}

input[type='text'],
input[type='number'] {
  /* border: 1px solid; */
  border: 0;
}

input[type='color'],
input[type='radio'],
input[type='checkbox'] {
  cursor: pointer;
}

.inline {
  display: inline-block;
  width: auto;
  margin: 0;
}

#palette .pal-sorting-method {
  width: 600px;
}

#palette .pal-sorting-method > div {
  margin: 4px 0 16px 0;
}

#palette .pal-sorting-method input {
  margin: 0;
  font-size: 90%;
  margin: 0;
  border-radius: 2px;
  flex: 1;
  padding: 8px;
}

#palette .pal-sorting-method button {
  flex: 0;
  margin: 0;
  margin-left: 16px;
}

#palette .pal-sorting-method p {
  margin: 4px 0;
  font-size: 80%;
  line-height: 1.4;
}

#palette .current-palette p {
  max-width: 384px;
  font-size: 80%;
  margin: 0;
}

#pal-swap-info {
  display: none;
}

.locked ~ #pal-swap-info {
  display: block;
}

#version {
  max-width: 100%;
}

#version,
#version a {
  text-align: right;
  color: var(--c-light-text);
  font-size: 12px;
}

fieldset {
  border: 1px solid #ccc;
  margin: 0;
}
